$default-color: #003153;
$second-color: #D8DFEA;

$navbar-color: $default-color;
//primary-brand in bootstrap
$panel-color: $default-color;
//success-brand in bootstrap
$button-color: $default-color;
//body color of primary-panel
$panel-body: #eaeaea;
// light text default color
$light-text: #555;

$screen-xs:                  480px !default;
$screen-sm:                  768px !default;
$screen-md:                  992px !default;
$screen-lg:                  1200px !default;


$navbar-default-link-active-color: $default-color !default;
$navbar-default-link-active-bg: #D8DFEA !default;